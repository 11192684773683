import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './assets/scss/navbar.module.scss';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import {
  Person as PersonIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Wysiwyg as WysiwygIcon,
} from '@mui/icons-material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HelpIcon from '@mui/icons-material/Help';
import { useApp } from '../../services/auth/useApp';
import ExtensionIcon from '@mui/icons-material/Extension';
import { App } from '../../services/object/App';
import { Law, lawConfig, newsConfig, popinConfig, salepointConfig, userConfig } from '../../services/object/Law';
import { useLaw } from '../../services/auth/useLaw';


const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gestionMenuOpen, setGestionMenuOpen] = useState(false);
  const location = useLocation();
  const drawerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLInputElement>(null);
  const gestionPages = ['/utilisateurs', '/concession', '/popins', '/accueilConfig'];
  const isOpen = gestionPages.includes(location.pathname);
  const { law } = useLaw();
  const { TOApp } = useApp();

  const toggleDrawer = () => {
    if (!drawerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    setDrawerOpen(!drawerOpen);
  }

  const handleClickGestionMenu = () => {
    setGestionMenuOpen(!gestionMenuOpen);
  }

  useEffect(() => {
    setGestionMenuOpen(isOpen);
  }, [location.pathname]);

  const closeDrawer = () => {
    setDrawerOpen(false);
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setDrawerOpen(false);
    }
  };

  return (
    <div>
      <React.Fragment key={'left'}>
        <div className={style.offcanvasMenu}>
          <input
            ref={buttonRef}
            onClick={toggleDrawer}
            type='checkbox'
            role='button'
            aria-label='Display the menu'
            className={style.menuButton}
            checked={drawerOpen}
          />
        </div>
        <Drawer
          ref={drawerRef}
          anchor={'left'}
          open={drawerOpen}
          className={`nav ${style.nav}`}
          onClose={toggleDrawer}
        >
          <Box className={style.box}>
            <List className={style.list}>

              <ListItem
                disablePadding
                className={style.listitem}
              >
                <Link onClick={closeDrawer} to='https://my.jra.tools/accueil'>
                  <ListItemButton>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    Accueil
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                disablePadding
                className={style.listitem}
              >
                <Link onClick={closeDrawer} to='https://my.jra.tools/toutes-actus'>
                  <ListItemButton>
                    <ListItemIcon><NewspaperIcon /></ListItemIcon>
                    Actualités
                  </ListItemButton>
                </Link>
              </ListItem>

              {TOApp
                .filter((app: App) => app.isJraApp === true && !(app.uid === '655cb071f404d0.41655118'))
                .map((app, index) => {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      className={style.listitem + ' ' + (window.location.href === app.url ? style.active : '')}
                    >
                      <Link onClick={closeDrawer} target="_blank" to={app.url}>
                        <ListItemButton>
                          <ListItemIcon>
                            {app.photo ? (
                              <div className={style.iconUrl}>
                                <img src={app.photo} alt={`${app.name} favicon`} />
                              </div>
                            ) : (
                              <ExtensionIcon />
                            )}
                          </ListItemIcon>
                          {app.name}
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  );
                })}

              <hr />

              {/* menu Gestion */}
              {(law && law.filter((law: Law) => (law.uid === userConfig ||
                law.uid === newsConfig ||
                law.uid === salepointConfig ||
                law.uid === popinConfig ||
                law.uid === lawConfig
              )).length >= 1) ?
                <>
                  <ListItemButton onClick={handleClickGestionMenu} className={`${style.submenuButton} ${gestionPages.includes(location.pathname) ? style.active : ''}`}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <div className={style.textIcon}>
                      <p>Gestion</p>
                      {gestionMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  </ListItemButton>
                  <Collapse in={gestionMenuOpen} timeout="auto" unmountOnExit className={style.submenu}>
                    <List component="div" disablePadding>
                      {(law && law?.filter((law: Law) => (law.uid === userConfig)).length === 1) ?
                        <ListItem
                          disablePadding
                          className={style.listitem}
                        >
                          <Link onClick={closeDrawer} to="https://my.jra.tools/utilisateurs">
                            <ListItemButton>
                              <ListItemIcon><PersonIcon /></ListItemIcon>
                              Gérer les utilisateurs
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        :
                        ""
                      }
                      {(law && law?.filter((law: Law) => (law.uid === newsConfig)).length === 1) ?
                        <ListItem
                          disablePadding
                          className={style.listitem}
                        >
                          <Link onClick={closeDrawer} to="https://my.jra.tools/accueilConfig">
                            <ListItemButton className='window'>
                              <ListItemIcon><NewspaperIcon /></ListItemIcon>
                              Gérer les actualités
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        :
                        ""
                      }
                      {(law && law?.filter((law: Law) => (law.uid === salepointConfig)).length === 1) ?
                        <ListItem
                          disablePadding
                          className={style.listitem}
                        >
                          <Link onClick={closeDrawer} to="https://my.jra.tools/concession">
                            <ListItemButton >
                              <ListItemIcon><EmojiTransportationIcon /></ListItemIcon>
                              Gérer les concessions
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        :
                        ""
                      }
                      {(law && law?.filter((law: Law) => (law.uid === popinConfig)).length === 1) ?
                        <ListItem
                          disablePadding
                          className={style.listitem}
                        >

                          <Link onClick={closeDrawer} to="https://my.jra.tools/popins">
                            <ListItemButton className='window'>
                              <ListItemIcon><WysiwygIcon /></ListItemIcon>
                              Gérer les popins
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        :
                        ""
                      }
                      {(law && law?.filter((law: Law) => (law.uid === lawConfig)).length === 1) ?
                        <ListItem
                          disablePadding
                          className={style.listitem}
                        >

                          <Link onClick={closeDrawer} to="https://my.jra.tools/gestion-des-droits">
                            <ListItemButton className='window'>
                              <ListItemIcon><AdminPanelSettingsIcon /></ListItemIcon>
                              Administrer les apps
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        :
                        ""
                      }

                    </List>
                  </Collapse>
                </>
                :
                ""
              }

            </List>
            <List className={style.list+ " " + " hidden"}>
              <ListItem disablePadding
                        className={style.listitem}>
                  <Link onClick={closeDrawer} to="https://my.jra.tools/aide">
                    <ListItemButton className='window'>
                      <ListItemIcon><HelpIcon /></ListItemIcon>
                      Besoin d'aide
                    </ListItemButton>
                  </Link>
                </ListItem>
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  )
};

export default Navbar;