import { useState, useEffect } from "react";

import style from './openWeather.module.scss';
import styledModal from '../modal/modal.module.scss';

import weatherDataByIdJson  from './weatherDataById.json';

import { openWeatherKey, openWeatherUrl } from "../../services/tools/openWeatherApiKey";

import activateLocImage1 from "./activateLocImage1.png";
import activateLocImage2 from "./activateLocImage2.png";

// @ts-ignore
import ReactAnimatedWeather from 'react-animated-weather';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type WeatherData = {
  [key: number]: {
    icon: string;
    color: string;
  };
  default: {
    icon: string;
    color: string;
  };
};

const OpenWeather = () => {
  const weatherDataById = weatherDataByIdJson as WeatherData;
  const [weatherData, setWeatherData] = useState({
    city: "",
    description: "",
    temperatureC: 0,
    icon: "CLOUDY",
    color: "#B6B6B6",
  });
  const [showAlertIcon, setShowAlertIcon] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  
  const getWeather = async (lat: number, lon: number) => {
    const data = await (await fetch(
      `${openWeatherUrl}weather?lat=${lat}&lon=${lon}&units=metric&APPID=${openWeatherKey}&lang=fr`
    )).json();
    const weatherId: number = data.weather[0].id;
  
    const weatherData = weatherDataById[weatherId] || weatherDataById.default;
  
    setWeatherData({
      city: data.name,
      temperatureC: Math.round(data.main.temp),
      description: data.weather[0].description,
      icon: weatherData.icon,
      color: weatherData.color,
    });
  };

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition(
      ({ coords }) => {
        getWeather(coords.latitude, coords.longitude);
        setShowAlertIcon(false);
      },
      () => {
        getWeather(47.186824, -1.601443);
        setShowAlertIcon(true);
      }
    );
  }, []);

  return (
    <div id={style.openWeather}>
      {showAlertIcon ? (
        <div
          className={`${style.weatherWrapper} ${style.notifWrapper}`}
          onClick={handleOpenModal}
        >
          <InfoOutlinedIcon
            style={{ color: "#ff3336", cursor: "pointer", marginRight: "10px" }}
          />
          <p>Activer la météo</p>
        </div>
      ) : (
        <div className={style.weatherWrapper}>
          <p>
            <span>{weatherData.city}</span>
            <span>{weatherData.description}</span>
          </p>
          <p>{weatherData.temperatureC}°C</p>

          <ReactAnimatedWeather
            icon={weatherData.icon}
            color={weatherData.color}
            size={35}
            animate={true}
          />
        </div>
      )}

      <Modal
        open={isModalOpen}
        className={styledModal.StyledModal}
        onClose={handleCloseModal}
      >
        <Box className={styledModal.box}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            className={styledModal.close}
          >
            <CloseIcon />
          </IconButton>
          <div className={styledModal.bigPopup}>
            <h2>Comment activer la météo ?</h2>
            <p>Dans la barre d'URL, à droite, cliquer sur l'icone de géolocalisation.</p>
            <img src={activateLocImage1} alt="Activer la localisation étape 1" />
            <p>Choisir "Toujours autoriser l'accès", puis cliquer sur "Ok".</p>
            <img src={activateLocImage2} alt="Activer la localisation étape 2" />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default OpenWeather;

