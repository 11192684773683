import React from 'react';
import { Cron } from '../../services/object/Cron';
import { Server } from '../../services/object/Server';
import FormPopup from './FormPopup';
import { Category } from '../../services/object/Category';

interface CronPopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefCron: React.RefObject<HTMLFormElement>,
    cron: Cron|undefined;
    TOServer: Server[];
    TOCategory: Category[];
    handleFormCronChange: (property: string, value: string| FileList | number | boolean | null) => void
  }
    const cronPopupChildrens = ({errorMessages, 
                                  formRefCron, 
                                  cron, 
                                  TOServer,
                                  TOCategory,
                                  handleFormCronChange} :CronPopupChildrensProps) => {

                 
    return ([
                <FormPopup errorMessages={errorMessages}
                           formRefCron={formRefCron}
                           cron={cron}
                           handleFormCronChange={handleFormCronChange}
                           TOServer={TOServer}
                           TOCategory={TOCategory}/>
           ]
    )
}
export default cronPopupChildrens;