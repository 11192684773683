import  { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { extractTimeForDateTime, sqlToJsDate } from "../../services/tools/translateDate";
import Service_Api from "../../services/Api";
import { Cron } from "../../services/object/Cron";
import style from './chart.module.scss';
import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function Graphique() {
  const Api = Service_Api();
  const [dataCron, setDataCron] = useState<(string | Date)[]>([]);
  const [data, setData] = useState<({
                                        type: string;
                                        role?: string;
                                        id: string;
                                    }[] | (string | Date))[]>();
  
    const [value, setValue] = useState(1);
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, value: number) => {
        if(value !== 1)
            navigate("/");

    };
    useEffect(() => {
        fecthData()
    }, []);    
    
    useEffect(() => {
        console.log(dataCron)
        setData([
            [
                { type: "string", id: "name" },
                { type: "string", role: "tooltip", id: "success" },
                { type: "date", id: "start" },
                { type: "date", id: "end" },

            ]
        , ...dataCron])
    }, [dataCron]);

    const fecthData = async() => {
    //get all cron (for row data)
        let response = await Api.get("cron/");
        if(response?.success)
            setDataCron(response.data.filter((cron : Cron)=>(cron?.executedAt !== null)).map((cron: Cron) => (
                [ 
                    // "Cron",
                    cron.name,
                    ((cron?.success === 1) ? "Succès" : (cron?.success === 2) ? "Échec" : "En pause"),
                    extractTimeForDateTime(new Date(sqlToJsDate(cron?.executedAt, true).getTime() - (parseFloat(cron?.time) *1000))),
                    extractTimeForDateTime(sqlToJsDate(cron?.executedAt, true))
                ]
        ))); 
    }
  
    const options = {
        timeline: {
          groupByRowLabel: true,
        },
        hAxis: {
            format: 'HH:mm'
        }
      };
  return (
            <div className="background">
                <div className="backsquare">
                    <h1 className='title2'>Liste des crons actif</h1>
                    <Tabs  className="tabsContainer"  value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Tableau" />
                        <Tab label="Graphique" className="selected" />
                    </Tabs>
                    <Chart  options={options} 
                            className={style.gantChart} 
                            chartType="Timeline" 
                            data={data} 
                            width="100%" 
                            height="100%"
                            chartLanguage={"fr"}
                             />
                </div>
            </div>
    );
}
