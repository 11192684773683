export const jsDateToLocalFr = (date: Date, time=false) => {
    if(time){
        return ("0" +date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth()+1).toString()).slice(-2) + "/" +date.getFullYear() + " " + ("0" +date.getHours()).slice(-2) + ":" + ("0" +date.getMinutes()).slice(-2) + ":" + ("0" +date.getSeconds()).slice(-2)
    }else{
        return ("0" +date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth()+1).toString()).slice(-2) + "/" +date.getFullYear()
    }
}
export const localFrToJsDate = (date: string, time=false) => {
    if(time){
        let d = date.split(" ")
        return new Date(Date.UTC(parseInt(d[0].split("/")[2]), parseInt(d[0].split("/")[1]) -1,parseInt(d[0].split("/")[0]),parseInt(d[1].split(":")[2]),parseInt(d[1].split(":")[1],0)));
    }else{
        return new Date(Date.UTC(parseInt(date.split("/")[2]), parseInt(date.split("/")[1])-1 ,parseInt(date.split("/")[0]),0,0,0));
    }
}
export const dateJstoSqlDate = (date: Date, time=false) => {
    if(time){
        return date.getFullYear() + "-" + ('0' +(date.getMonth()+1)).slice(-2) + "-"+ ('0' +(date.getDate())).slice(-2) + " " + ("0" +date.getHours()).slice(-2) + ":" + ("0" +date.getMinutes()).slice(-2) + ":" + ("0" +date.getSeconds()).slice(-2)
    }else{
        return date.getFullYear() + "-" + ('0' +(date.getMonth()+1)).slice(-2) + "-"+ ('0' +(date.getDate())).slice(-2)
    }
}

export const sqlToJsDate = (sqlDate :string, time=false) => {
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
    if(sqlDate)
    {
        let sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        let sYear = Number(sqlDateArr1[0]);
        let sMonth = Number(sqlDateArr1[1]) - 1;
        let sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        let sDay = Number(sqlDateArr2[0]);
        if(time){
            let sHour = Number(sqlDateArr2[1].split(":")[0]);
            let sMinute = Number(sqlDateArr2[1].split(":")[1]);
            let sSecond = Number(sqlDateArr2[1].split(":")[2]);
            return new Date(Date.UTC(sYear,sMonth,sDay,sHour,sMinute,sSecond));
        }else
            return new Date(sYear,sMonth,sDay);
    }else
        return new Date();
}

export function extractTimeForDateTime(date: Date){
    console.log(date)
    return new Date(0,0,0,date.getHours(), date.getMinutes(), date.getSeconds() );
}