import  { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("JRA_token") ?? "";
    return tokenString
  };
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken:string) => {
    localStorage.setItem("JRA_token", JSON.stringify(userToken).replaceAll('"', ""))
    setToken(userToken);
  };

  const removeToken = ()=>{
    localStorage.removeItem("JRA_token")

  }

  return {
    removeToken: removeToken,
    setToken: saveToken,
    token
  }
}